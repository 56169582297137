import React, { useEffect, useContext, useRef, useState, Fragment } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import ImagePreview from '../../components/common/ImagePreview';
import Sidebar from '../../components/common/ArticleSidebar'
import SocialMedia from '../../components/common/SocialMedia'
import lsrImg from '../../assets/img/pages/vr-games/VIRSAT-Life-Saving-Rules-Aware.jpg'
import empty from '../../assets/img/icons/empty.png'
import mainImg from '../../assets/img/pages/articles/impress-visitors-sophisticated-safety-induction-life-saving-rules.jpg'

const RoadIncidentsMiddleEast = ({location}) => {
  const [pageTitle] = useState('How to Impress Your Visitors with a Sophisticated Safety Induction on the Life-Saving Rules?')
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  const slug = useState(location.pathname.split('/')[2]);
  let endDiv = useRef(null);
  const [endDivPosition, setEndDivPosition] = useState(0);

  const [showImgPrev,setShowImgPrev]=useState(false)
  const [activeImg,setActiveImg]=useState(empty)

  const handeleActiveImg = (img)=> {
    setActiveImg(img)
    setShowImgPrev(true)
  }

  const updatePosition = () => {
    setEndDivPosition(endDiv.current.getBoundingClientRect().top)
  };

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    
    return () => window.removeEventListener("scroll", updatePosition);
  }, [endDivPosition]);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Fragment>
      <ImagePreview img={activeImg} orientation="landscape" onClick={()=>setShowImgPrev(!showImgPrev)} show={showImgPrev}/>
      <Layout>
        <Seo
          title={pageTitle}
          description='IOGP introduced 9 Life-Saving Rules. Rules which save lives and deal with the most hazardous activities in the Oil and Gas industry, construction, and manufacturing.'
          image={mainImg}
          ogType='article'
        />
        <PageBanner title='Articles' useDiv={true}/>
        <section
          className='main-container single-article hide-container'
          ref={container}
        >
          <div className='row less-mar bottom-pad'>
            <SocialMedia 
              pageUrl={location.href} 
              pageTitle={pageTitle}
              endDivPosition={endDivPosition}
            />
            {/* Article */}
            <article className='column article-details'>
              <header>
                <h1 className="main-title small primary-color">
                  <strong>How to Impress Your Visitors with a Sophisticated Safety Induction on the Life-Saving Rules</strong>
                </h1>
                <div className='author-date'>
                  By <strong>Willem Peuscher</strong>
                  <span className='spacer'>|</span>June 02, 2022
                  <span className='spacer'>|</span>5 minutes read
                </div>
              </header>
              <div 
                onClick={()=>handeleActiveImg(mainImg)} 
                aria-hidden className='article-main-image'>
                  <StaticImage
                    src='../../assets/img/pages/articles/impress-visitors-sophisticated-safety-induction-life-saving-rules.jpg'
                    alt='How to Impress Your Visitors with a Sophisticated Safety Induction on the Life-Saving Rules?'
                    title='How to Impress Your Visitors with a Sophisticated Safety Induction on the Life-Saving Rules?'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
              </div>
              <h2 className="main-subtitle">What are the Life-Saving Rules</h2>
              <p className="justify mar-bottom no-mar-top">IOGP introduced 9 Life-Saving Rules. Rules which save lives and deal with the most hazardous activities in the Oil and Gas industry, construction, and manufacturing.</p>
              
              <p className="justify no-mar-top">Find out how you can impress your visitors with a Sophisticated Safety Induction on the Life-Saving Rules by downloading the full article and subscribing to our newsletter.</p>
              <div style={{'marginTop':'20px', 'zIndex':2}}>
                <Link to="/lp/download-article/" state={{ slug: slug[0] }} className="button-link">Subscribe and Download</Link>
              </div>
                <hr/>
              <div className="footer-note primary-bg">
                <div>
                  <p className='no-mar'>
                  If you want to learn more, see the brochure - <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf">Life-Saving Rules Aware-Knowledge</a>, or write to <a href="mailto:info@virsat.com">info@virsat.com</a>. 
                  </p>
                </div>
                <div>
                  <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf"><img src={lsrImg} alt='VIRSAT Life Saving Rules Aware'/></a>
                </div>
              </div>

              <div style={{height:'1px','width':'1px'}} ref={endDiv}/>
            </article>
            {/* End: Article */}

            {/* Sidebar */}
            <Sidebar slug={slug} endDivPosition={endDivPosition}/>
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}

export default RoadIncidentsMiddleEast
